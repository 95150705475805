/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./Como_llegar.css";

export function Como_llegar() {
  return (
    <section id="¿cómo llegar?Section" className="llegar">
      <h2 className="titulo-llegar">¿Cómo llegar?</h2>
      <iframe
        className="mapa"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12188.858519544934!2d-72.2637176!3d-40.2042884!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x323ed12c4c573551!2sPosada%20Del%20Turista!5e0!3m2!1ses!2scl!4v1666572015306!5m2!1ses!2scl"
        width="65%"
        height="65%"
      ></iframe>
    </section>
  );
}
