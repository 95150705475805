import React from "react";

import departamento1 from "../../assets/departamento1.jpeg";
import departamento2 from "../../assets/departamento2.jpeg";
import departamento3 from "../../assets/departamento3.jpeg";
import pieza1_1 from "../../assets/pieza1_1.jpeg";
import pieza1_2 from "../../assets/pieza1_2.jpeg";
import pieza1_3 from "../../assets/pieza1_3.jpeg";
import pieza2_1 from "../../assets/pieza2_1.jpeg";
import pieza2_2 from "../../assets/pieza2_2.jpeg";
import pieza2_3 from "../../assets/pieza2_3.jpeg";
import "./Hospedaje.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export function Hospedaje() {
  return (
    <section id="hospedajeSection">
      <h2 className="titulo-hospedaje">Hospedaje</h2>
      <div className="carousel-row">
        <div className="departamento">
          <Carousel showThumbs={false}>
            <div>
              <img
                src={departamento1}
                alt="Departamento 1"
                className="carousel-image-hospedaje"
              />
            </div>
            <div>
              <img
                src={departamento2}
                alt="Departamento 2"
                className="carousel-image-hospedaje"
              />
            </div>
            <div>
              <img
                src={departamento3}
                alt="Departamento 3"
                className="carousel-image-hospedaje"
              />
            </div>
          </Carousel>
          <h1>Departamento</h1>
          <p>
            Departamento independiente <br /> con cama matrimonial y cama
            single, televisión, con baño y cocina.
          </p>
        </div>
        <div className="pieza-doble">
          <Carousel showThumbs={false}>
            <div>
              <img
                src={pieza1_1}
                alt="Departamento 1"
                className="carousel-image-hospedaje"
              />
            </div>
            <div>
              <img
                src={pieza1_2}
                alt="Departamento 2"
                className="carousel-image-hospedaje"
              />
            </div>
            <div>
              <img
                src={pieza1_3}
                alt="Departamento 3"
                className="carousel-image-hospedaje"
              />
            </div>
          </Carousel>

          <h1>Pieza Doble</h1>
          <p>
            Pieza doble con camas
            <br /> de plaza <sup>1</sup>/<sub>2</sub> y con baño privado.
          </p>
        </div>
        <div className="pieza-matrimonial">
          <Carousel showThumbs={false}>
            <div>
              <img
                src={pieza2_1}
                alt="Departamento 1"
                className="carousel-image-hospedaje"
              />
            </div>
            <div>
              <img
                src={pieza2_2}
                alt="Departamento 2"
                className="carousel-image-hospedaje"
              />
            </div>
            <div>
              <img
                src={pieza2_3}
                alt="Departamento 3"
                className="carousel-image-hospedaje"
              />
            </div>
          </Carousel>
          <h1>Pieza Matrimonial</h1>
          <p>
            Pieza con cama matrimonial,
            <br /> cama single y baño privado.
          </p>
        </div>
      </div>
    </section>
  );
}
