import React from "react";
import comida1 from "../../assets/bife.jpeg";
import comida2 from "../../assets/cazuela.jpeg";
import comida3 from "../../assets/merluza.jpeg";
import comida4 from "../../assets/vacuno.jpeg";
import comida5 from "../../assets/salmon.jpeg";
import comida6 from "../../assets/pollo.jpg";
import "./Comidas.css";

export function Comidas() {
  // Array de objetos con los datos de cada item
  const comidasData = [
    {
      imagen: comida1,
      titulo: "BIFE A LO POBRE",
      descripcion: "Con papas fritas y huevos de campo.",
    },
    {
      imagen: comida2,
      titulo: "CAZUELA DE VACUNO",
      descripcion:
        "Rica en verduras, papas nuevas, choclos y aliños del huerto.",
    },
    {
      imagen: comida3,
      titulo: "MERLUZA CON AGREGADO",
      descripcion:
        "Los agregados pueden ser: Papas fritas, puré, arroz o ensalada.",
    },
    {
      imagen: comida4,
      titulo: "ESTOFADO DE VACUNO CON AGREGADO",
      descripcion:
        "Los agregados pueden ser: Papas fritas, puré, arroz o ensalada.",
    },
    {
      imagen: comida5,
      titulo: "SALMON CON AGREGADO",
      descripcion:
        "Los agregados pueden ser: Papas fritas, puré, arroz o ensalada.",
    },
    {
      imagen: comida6,
      titulo: "POLLO ARVEJADO",
      descripcion:
        "Con ricas arvejas del huerto, papitas nuevas y aliños caseros.",
    },
    // Agrega más objetos para más elementos en el grid
  ];

  return (
    <section id="comidasSection">
      <h2 className="titulo-comidas">Comidas</h2>
      <div className="grid-container">
        {comidasData.map((comida, index) => (
          <div key={index} className="grid-item">
            <div className="image-container">
              <img src={comida.imagen} alt={comida.titulo} />
            </div>
            <div className="text-container">
              <h2>{comida.titulo}</h2>
              <p>{comida.descripcion}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
