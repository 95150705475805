import React, { useState, useEffect } from "react";
import "./Header.css";
import logo from "../../assets/logo.jpg";

export function Header() {
  const navLinks = [
    "Inicio",
    "Nosotros",
    "Hospedaje",
    "Comidas",
    "¿Cómo llegar?",
    "Lugares para visitar",
    "Contacto",
  ];

  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState(null);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      const menu = document.querySelector(".navContainers nav");

      if (!window.matchMedia("(max-width: 768px)").matches) {
        if (menu.style.display === "none") {
          menu.style.display = "flex";
        }
        if (menu.style.display === "block") {
          menu.style.display = "flex";
        }
      }

      if (window.matchMedia("(max-width: 768px)").matches) {
        if (menu.style.display === "flex") {
          menu.style.display = "none";
        }
      }
    };

    window.addEventListener("resize", handleWindowSizeChange);

    // Llama a la función una vez al cargar la página
    handleWindowSizeChange();

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const renderNavLink = (content) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    const handleClickNav = () => {
      document
        .getElementById(scrollToId)
        .scrollIntoView({ behavior: "smooth" });
      if (isBurgerMenuActive) {
        handleBurgerMenu();
      }
    };
    return (
      <ul key={content}>
        <li>
          <button onClick={handleClickNav}>{content}</button>
        </li>
      </ul>
    );
  };

  const handleBurgerMenu = () => {
    const menu = document.querySelector(".navContainers nav"); // Selecciona el elemento del menú

    if (menu.style.display === "block") {
      menu.style.display = "none";
      setIsBurgerMenuActive(false); // Si el menú está visible, ocúltalo
    } else {
      menu.style.display = "block";
      setIsBurgerMenuActive(true); // Si el menú está oculto, muéstralo
    }
  };

  return (
    <header className="headers">
      <div className="containers">
        <div className="navContainers">
          <img className="logo" src={logo} alt="logo" />
          <nav>{navLinks.map((nav) => renderNavLink(nav))}</nav>
          <button className="burgerMenu" onClick={handleBurgerMenu}>
            <span className="burgerLine"></span>
            <span className="burgerLine"></span>
            <span className="burgerLine"></span>
          </button>
        </div>
      </div>
    </header>
  );
}
