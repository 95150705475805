import React from "react";

import { Carousel as Cart } from "react-carousel-minimal";
import logo from "../../assets/logo2.jpg";
import casa from "../../assets/casa.jpg";
import comedor1 from "../../assets/comedor1.jpeg";
import comedor2 from "../../assets/comedor2.jpeg";
import terraza from "../../assets/terraza.jpeg";
import casaFrente from "../../assets/casa_frente.jpeg";
import "./Home.css";

export function Home() {
  const images = [
    { image: logo },
    { image: casa },
    { image: casaFrente },
    { image: comedor1 },
    { image: comedor2 },
    { image: terraza },
  ];

  return (
    <section id="inicioSection">
      <div className="carousel-container">
        <Cart
          data={images}
          time={3000}
          width="100%"
          height="100%"
          captionStyle={{
            fontSize: "2rem",
            fontWeight: "bold",
          }}
          radius="10px"
          slideNumber={false}
          slideBackgroundColor="transparent"
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideImageFit="cover"
          thumbnails={true}
          thumbnailWidth="100px"
          style={{
            textAlign: "center",
            margin: "auto",
          }}
        />
      </div>
    </section>
  );
}
