export class Contact_Api {
  async contacto(data) {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
        console.log(key, data[key]);
      });

      if (data.email) {
        formData.delete("email");
        formData.append("correo", data.email);
      }

      const url = "https://www.laposadadelturista.cl/contacto.php";
      const params = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(url, params);

      if (response.status !== 200) throw response;

      return response;
    } catch (error) {
      console.log("hola");
      throw error;
    }
  }
}
