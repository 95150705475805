import React, { useState, useEffect } from "react";

import iglesia from "../../assets/iglesia_del_lago_llifen.jpg";
import maihue from "../../assets/lago_maihue.jpg";
import pesca from "../../assets/pesca_deportiva.jpg";
import playa from "../../assets/muelle.jpeg";
import termas from "../../assets/termas_chihuio.jpg";
import salto from "../../assets/salto_nilahue.jpg";
import toribio from "../../assets/toribio.jpg";
import futangue from "../../assets/futangue.jpeg";
import { Loader, Pagination, Image, Button } from "semantic-ui-react";
import { map } from "lodash";

import "./Lugares.css";

export function Lugares() {
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);
  const [images, setImages] = useState(null);

  useEffect(() => {
    try {
      if (page === 1) {
        setImages([
          {
            id: "1",
            src: iglesia,
            alt: "Imagen 1",
            nombre: "Capilla Nuestra Señora Del Lago",
            descripcion:
              "Construida el año 1998, se ubica en el centro de Llifén.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono/Capilla+Nuestra+Se%C3%B1ora+del+Lago,+Futrono/@-40.1985876,-72.2608717,17z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616bf3fae01341b:0xd44e7ce7610875b4!2m2!1d-72.2578068!2d-40.1974688!3e0",
          },
          {
            id: "2",
            src: maihue,
            alt: "Imagen 2",
            nombre: "Lago Maihue",
            descripcion:
              "Ubicado a 34 kilómetros de Llifén, con hermosas playas y camping para disfrutar en familia.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono/Lago+Maihue,+Los+R%C3%ADos/@-40.2316838,-72.2003177,12z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616cc40f8084a49:0xfc76ba2fed39612!2m2!1d-72.0349647!2d-40.273221!3e0",
          },
        ]);
        setPagination({
          page: 1,
          pages: 4,
        });
      }
      if (page === 2) {
        setImages([
          {
            id: "3",
            src: pesca,
            alt: "Imagen 3",
            nombre: "Pesca Deportiva",
            descripcion:
              "Con bajadas de lago Maihue y llegada a la desembocadura al lago del puerto Llifén.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono/Lago+Maihue,+Los+R%C3%ADos/@-40.2316838,-72.2003177,12z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616cc40f8084a49:0xfc76ba2fed39612!2m2!1d-72.0349647!2d-40.273221!3e0",
          },
          {
            id: "4",
            src: playa,
            alt: "Imagen 4",
            nombre: "Playa Huequecura",
            descripcion:
              "Ubicada en Llifén, a pasos del centro, hermosa playa con salvavidas y una linda costanera para caminar en familia.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono/Playa+Huequecura,+Futrono/@-40.1951149,-72.2615015,16.06z/data=!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616bf3d374bebc1:0x990d50399f0d22e1!2m2!1d-72.2582336!2d-40.1903636!3e0",
          },
        ]);

        setPagination({
          page: 2,
          pages: 4,
        });
      }

      if (page === 3) {
        setImages([
          {
            id: "5",
            src: termas,
            alt: "Imagen 5",
            nombre: "Termas Chihuio",
            descripcion:
              "Construida el año 1998, se ubica en el centro de Llifén.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono/Termas+de+Chihuío,+Futrono/@-40.2121251,-72.4274591,10z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9611353bbf6835fb:0xb8642c434739c42a!2m2!1d-71.9359603!2d-40.194279!3e0?entry=ttu",
          },
          {
            id: "6",
            src: salto,
            alt: "Imagen 6",
            nombre: "Salto Nilahue",
            descripcion:
              "Ubicado a 14 kilómetros de Llifén, camino a Lago Ranco, encontrarás una hermosa cascada con senderos para recorrer.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono/Salto+del+Nilahue,+Lago+Ranco/@-40.2481624,-72.2466299,12.62z/data=!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616c676196014b7:0x68a35f9ee460be22!2m2!1d-72.1723471!2d-40.284815!3e0",
          },
        ]);
        setPagination({
          page: 3,
          pages: 4,
        });
      }
      if (page === 4) {
        setImages([
          {
            id: "7",
            src: toribio,
            alt: "Imagen 5",
            nombre: "Cerro Pico Toribio",
            descripcion:
              "El cerro Pico Toribio nos permite contemplar la naturaleza en todo su esplendor, con vistas magníficas hacia el lago Ranco y el Mocho-Choshuenco. ",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono,+Los+Ríos/Parque+Pico+Toribio+-+Quiman+alto,+Futrono,+Los+Ríos/@-40.1601501,-72.3360394,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616bd8ee18d3577:0x6df91e19225f6065!2m2!1d-72.3176365!2d-40.122245!3e2?hl=es&entry=ttu",
          },
          {
            id: "8",
            src: futangue,
            alt: "Imagen 6",
            nombre: "Parque Futangue",
            descripcion:
              "Ubicado en medio de la imperdible ruta de los lagos que abarca desde Pucón hasta Puerto Varas, en Futangue encontrarás la combinación perfecta de aventura y comodidad, desde excursiones por el parque hasta exclusivos servicios de hotelería, spa y restaurant.",
            url: "https://www.google.com/maps/dir/Llifen,+Futrono,+Los+Ríos/Parque+Futangue+-+T-85,+Lago+Ranco,+Los+Ríos/@-40.2660546,-72.3013915,12z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x9616bf6b78079fa3:0x3e167f1d8e017a9c!2m2!1d-72.2596554!2d-40.2000213!1m5!1m1!1s0x9616c47d7492d9df:0x1c1557051aea27ac!2m2!1d-72.2272155!2d-40.3323477!3e0?entry=ttu",
          },
        ]);
        setPagination({
          page: 4,
          pages: 4,
        });
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function cambiarPage(_, data) {
    setPage(data.activePage);
  }
  if (!images) return <Loader active inline="centered" />;

  return (
    <section id="lugares para visitarSection" className="lugares">
      <div className="list-posts-web">
        <h2 className="titulo-lugares">Lugares para visitar</h2>
        <div className="list">
          {map(images, (image) => (
            <div key={image.id} className="item">
              <Image className="image-lugar" src={image.src} />
              <div className="img-content">
                <h3>{image.nombre}</h3>
                <p>{image.descripcion}</p>
                <Button as="a" href={image.url} color="teal" target="_blank">
                  Como llegar
                </Button>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          <Pagination
            totalPages={pagination.pages}
            defaultActivePage={pagination.page}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            prevItem={pagination.page === 1 ? null : undefined}
            nextItem={pagination.page === pagination.pages ? null : undefined}
            onPageChange={cambiarPage}
          />
        </div>
      </div>
    </section>
  );
}
