import React from "react";
import "./Nosotros.css";
import casa from "../../assets/Casa_2.jpeg";

export function Nosotros() {
  return (
    <section id="nosotrosSection">
      <div className="Texto">
        <p className="titulo-nosotros">Nosotros</p>
        <h2 className="subtitulo-nosotros">La posada del turista</h2>
        <p>
          Somos una Residencial de Turismo con gran calidad humana, ambiente muy
          familiar con trayectoria de más de 20 años, atendida por sus propias
          dueñas, con rica comida chilena. Un lugar ideal para descansar,
          conocer, pescar y disfrutar del Lago Ranco.
        </p>
      </div>
      <div>
        <img className="image-nosotros" src={casa} alt="Imagen" />
      </div>
    </section>
  );
}
