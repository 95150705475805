/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  Header,
  Home,
  Nosotros,
  Hospedaje,
  Comidas,
  Como_llegar,
  Lugares,
  Contact,
} from "./components";
import { map } from "lodash";
import { Button } from "semantic-ui-react";
import Swal from "sweetalert2/src/sweetalert2.js";
import pesca from "./assets/pesca_deportiva.jpg";

const socialData = [
  {
    type: "facebook",
    link: "https://facebook.com/villachumpeco/?_rdc=1&_rdr",
    color: "facebook",
  },
  {
    type: "instagram",
    link: "https://www.instagram.com/laposadadelturista_llifen/",
    color: "pink",
  },
  {
    type: "whatsapp",
    link: "https://wa.me/56963713625",
    color: "green",
  },
];

export default function App() {
  const evento = () => {
    Swal.fire({
      title: "<h2 style='color:red;'>AVISO PESCADORES!</h2>",
      text: "Se informa que la pesca estará abierta hasta el 31 de mayo del 2024. Los esperamos.",
      imageUrl: pesca,
      imageWidth: 200,
      imageHeight: 300,
      imageAlt: "Custom image",
      confirmButtonText: "Cerrar",
      confirmButtonColor: "teal",
      backdrop: `
      rgba(0,0,0,0.8)
      no-repeat`,
    });
  };

  return (
    <div className="page">
      {new Date() < new Date("2024-05-31") ? evento() : null}
      <Header />
      <Home />
      <Nosotros />
      <Hospedaje />
      <Comidas />
      <Como_llegar />
      <Lugares />
      <Contact />

      <div className="fixed-buttons-container">
        {map(socialData, (social) => (
          <Button
            className="fixed-button"
            key={social.type}
            as="a"
            target="_blank"
            href={social.link}
            color={social.color}
            icon={social.type}
            size="big"
          ></Button>
        ))}
      </div>
    </div>
  );
}
